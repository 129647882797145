/* https://tailwindcss.com/docs/using-with-preprocessors#build-time-imports */
@import 'tailwindcss/base';
@import 'tailwindcss/components';

@import '../../../../../packages/editor/src/tailwind/components.css';
@import '../../../../../packages/editor/src/tailwind/utilities.css';
@import './components.css';

@import 'tailwindcss/utilities';

/* print and pdf styles */
@layer utilities {
  .serlo-print-style {
    @apply text-xs;

    body,
    header {
      background-color: white;
    }
    header [data-qa='quickbar-input'] {
      @apply hidden;
    }
    .print\:block {
      @apply !block;
    }
    #__next header > div {
      padding-bottom: 0;
    }
    #__next {
      .print\:hidden,
      > footer,
      #searchform,
      header > button,
      #notification-wrapper,
      nav,
      aside,
      h1 > span > svg {
        @apply !hidden;
      }
      main {
        @apply relative block w-full max-w-full;
      }
      img,
      table {
        page-break-inside: avoid;
      }
      .serlo-exercise-wrapper {
        margin-top: 0;
        border-bottom: 1px solid black;
        padding-bottom: 1rem;
        margin-bottom: 2rem;
      }
      .serlo-ul,
      .serlo-ol {
        list-style-type: initial;
        li:before {
          display: none;
        }
      }
      .serlo-ol {
        list-style-type: decimal;
      }
    }
    .header-breadcrumbs,
    a,
    code,
    code span,
    html,
    pre,
    pre:before,
    time {
      @apply !bg-white !text-black;
    }
  }
}
/* Some exceptional cases where we can't / don't want to set classnames directly.
 *
 * Don't set css attributes in this file. If an utility is missing, add it to the config instead.
 * Use apply and selectors to target elements.
 *
 * Try to keep this file minimal.
 *
 * Classes added in this file have no autocomplete! Add them into the config instead.
 */

/* Make clicks pass-through */
#nprogress {
  @apply pointer-events-none;
}

#nprogress .bar {
  @apply fixed left-0 top-0 z-50 bg-brand;
  @apply h-1 w-full;
  @apply z-[9999];
}

/* Fancy blur effect */
#nprogress .peg {
  @apply absolute right-0 block h-full w-24;
  @apply opacity-100 shadow-brand;
  @apply -translate-y-1 rotate-3;
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  @apply fixed right-4 top-4 z-50 block;
  @apply z-[9999];
}

#nprogress .spinner-icon {
  @apply h-6 w-6 rounded-full border-3 border-brand;
  @apply animate-spin-fast border-l-transparent border-t-transparent;
}

.nprogress-custom-parent {
  @apply relative hidden;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  @apply absolute;
}

table.gstl_50.gssb_c {
  /* TODO: restore */
  /* @apply serlo-input-font-reset z-50; */
  @apply !right-1 left-1 mt-0.5 !w-auto;
  @apply w-auto sm:ml-2.5 sm:mt-0.5;

  .gssb_a td {
    @apply !whitespace-normal;
  }
}

/* TODO: Investigate if we need this in editor */
h1,
h2,
main b {
  @apply tracking-slightestly-tighter;
}
body {
  @apply tracking-slightly-tighter;
}
